/*
  Estilo para los campos de entrada en la tabla de KPIs.
  `.InputTable` define las dimensiones, fondo, borde y bordes redondeados de los inputs.
*/
.InputTable {
  width: 100px; /* Ancho fijo de 100 píxeles */
  height: 32px; /* Altura fija de 32 píxeles */
  background: #FFFFFF; /* Fondo blanco */
  /* Neutrals/Platinum */
  border: 1px solid #F0F0F0; /* Borde sólido de 1 píxel con color #F0F0F0 */
  border-radius: 8px; /* Bordes redondeados con un radio de 8 píxeles */
}

/*
  Estilo para los campos de resultado en la tabla de KPIs.
  `.InputTableResult` define la altura, fondo, borde y bordes redondeados de los campos de resultado.
*/
.InputTableResult{
  height: 32px; /* Altura fija de 32 píxeles */
  background: #FFFFFF; /* Fondo blanco */
  /* Neutrals/Platinum */
  border: 1px solid #F0F0F0; /* Borde sólido de 1 píxel con color #F0F0F0 */
  border-radius: 8px; /* Bordes redondeados con un radio de 8 píxeles */
}

/*
  Clase para alinear el texto al centro.
  `.text-align-center` centra horizontalmente el contenido de texto dentro del elemento.
*/
.text-align-center{
  text-align: center; /* Alinea el texto horizontalmente al centro */
}

/*
  Clase para indicar una calificación baja.
  `.bajo` aplica un color rojo intenso para resaltar un rendimiento bajo.
*/
.bajo{
  color: #F20505; /* Color rojo (#F20505) para resaltar bajo rendimiento */
}

/*
  Clase para indicar una calificación regular.
  `.regular` aplica un color naranja para resaltar un rendimiento aceptable.
*/
.regular{
  color: #F27405; /* Color naranja (#F27405) para resaltar rendimiento regular */
}

/*
  Clase para indicar una calificación buena.
  `.bueno` aplica el mismo color naranja que `.regular` para mantener la consistencia.
*/
.bueno{
  color: #F27405; /* Color naranja (#F27405) para resaltar buen rendimiento */
}

/*
  Clase para indicar una calificación excelente.
  `.ecxelente` aplica un color verde para resaltar un rendimiento excelente.
*/
.ecxelente{
  color: #10B102; /* Color verde (#10B102) para resaltar excelente rendimiento */
}
