@import url("https://fonts.googleapis.com/css2?family=Bangers&display=PlusJakartaSans");

:root {
    --main-bg-color: #C9F2FF;
    --main-bg-color-desactive: #F0F0F0;
    --main-border-radius: 16px 16px 0px 0px;
    --main-color: #3B63A2;
    --main-color-desactive: #939396;
    --main-width: 500px;
    --main-margin: 0 auto;
    --main-border-style: none;
    --main--width--modal-info: 85%;
    --main--margin--modal--info: 0 auto;
    --main--bg--color--Page: #3B63A2;
}

.nav-tabs .nav-link {
    background-color: var(--main-bg-color-desactive) !important;
    color: var(--main-color-desactive) !important;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    border-radius: 16px 16px 0px 0px;
}

.nav-link.active {
    background-color: var(--main-bg-color) !important;
    border-radius: var(--main-border-radius);
    color: var(--main-color);
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    font-family: Plus Jakarta Sans, sans-serif !important;
    line-height: 120%;
    font-style: normal;
}

.jYAvTT {
    width: var(--main-width) !important;
    margin: var(--main-margin) !important;
}

/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-style: var(--main-border-style) !important;
    align-items: center;
    padding: 6px 8px;
    gap: 4px;
    width: auto;
    height: 50px;
    background: #3B63A2;
    border-radius: 100px !important;
} */

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    z-index: 1;
    color: #ffff;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.carousel-inner {
    width: var(--main--width--modal-info) !important;
    margin: var(--main--margin--modal--info) !important;
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: var(--main--bg--color--Page) !important;
}