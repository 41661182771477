.loader {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-top: 8px solid #3B63A2;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 1s linear infinite;
    margin: auto;
    margin-top: 50px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  